* {
  --font-color: rgb(34, 34, 34);
  --secondary-color: rgba(113, 46, 255, 0.9);
  --underline-color: rgba(113, 46, 255, 0.5);
  --dark-bg: rgba(15, 0, 46, 1);
  --dark-card-bg: rgba(20, 0, 61, 1);
  --dark-text: rgba(255, 255, 255, 0.87);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-weight: 500;
  letter-spacing: 1px;
  scroll-behavior: smooth;
}

body {
  background-image: url(/static/media/bg.ce5d3fc8.png),
    url(/static/media/bg2.8e412888.png),
    url(/static/media/bg3.5a69ecd1.png),
    url(/static/media/bg4.e663eb30.png),
    url(/static/media/bg5.4cab44ab.png),
    url(/static/media/bg2.8e412888.png);
  background-repeat: no-repeat;
  background-position: top -25px left -550px, top -400px right -550px,
    top 800px right -600px, top 1300px left -450px, top 1600px right -400px,
    top 2100px left -500px;
  background-size: 800px;
}

img {
  width: 100%;
  height: 100%;
}

.container {
  min-height: 100vh;
  width: 100%;
  padding: 5% 15% 0 15%;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
  .container {
    padding: 5% 5% 5% 5%;
  }
}

.Button_button__35LrK {
  display: block;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  height: 3rem;
  width: 10rem;
  margin-top: 1.4rem;
  background-color: var(--secondary-color);
  text-align: center;
  line-height: 3rem;
  font-size: 1.1rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -4px -4px 12px 0 rgba(255, 255, 255, 0.3);
  transition: all ease 0.1s;
}

.Button_button__35LrK:hover {
  box-shadow: 8px 8px 12px 0 rgba(0, 0, 0, 0.25),
    -4px -4px 12px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  transition: all ease 0.1s;
}

.Button_dark__2jjK0,
.Button_dark__2jjK0:hover {
  box-shadow: none;
}

.Landing_landing__3Nv-E {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Landing_welcome__vs8TL {
  flex: 1 1 50%;
  line-height: 2;
}

.Landing_welcome__vs8TL h1 span:first-child {
  font-weight: 500;
  font-size: 2rem;
  font-family: "Urbanist", sans-serif;
  letter-spacing: 2px;
}

.Landing_welcome__vs8TL h1 span:last-child {
  font-size: 4rem;
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
  letter-spacing: 2px;
  outline: none;
  text-transform: uppercase;
  background: linear-gradient(
    -90deg,
    rgba(113, 46, 255, 0.9) 0%,
    #de005e 25%,
    #d348f6 50%,
    #de5900 75%,
    #ffffff 100%
  );
  background-size: 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: Landing_animate__1bmoB 10s linear infinite;
          animation: Landing_animate__1bmoB 10s linear infinite;
}
@-webkit-keyframes Landing_animate__1bmoB {
  to {
    background-position: 400%;
  }
}
@keyframes Landing_animate__1bmoB {
  to {
    background-position: 400%;
  }
}

.Landing_welcome__vs8TL h2 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 3;
  font-family: "Urbanist", sans-serif;
}

.Landing_avatar__39fD- {
  flex: 1 1 20%;
}

@media only screen and (max-width: 576px) {
  .Landing_landing__3Nv-E {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Landing_avatar__39fD- {
    display: none;
  }
  .Landing_welcome__vs8TL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
  }
}

.NavLinks_navItem__31lJM {
  display: inline-block;
  height: auto;
  width: 8rem;
  text-align: left;
}

.NavLinks_navItem__31lJM ::after {
  display: block;
  content: "";
  border-bottom: solid 3px var(--underline-color);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
}

.NavLinks_navItem__31lJM :hover:after {
  -webkit-transform: scaleX(0.6);
          transform: scaleX(0.6);
}

.NavLinks_navItem__31lJM a {
  display: inline-block;
  width: 50%;
  height: auto;
  font-size: 1.2rem;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  /* font-family: "Raleway", sans-serif;
  font-family: 'Raleway', sans-serif; */
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
}

.NavLinks_mobileMenuItem__2tpMU {
  margin: 2rem;
}
.NavLinks_mobileMenuItem__2tpMU a {
  color: #fff;
  font-weight: 500;
  font-size: 3rem;
}

.NavSocial_navSocial__3zFZh {
    display: inline-block;
    margin-left: 2rem;
    text-align: right;
}

.NavSocial_socialIcons__2YPuY {
    font-size: 1.7rem;
    color: var(--secondary-color);
    margin: auto;
}

.NavSocial_mobileSocialIcons__28UAW {
    color: #fff;
    font-size: 3rem;
    margin: 2rem;
}

.NavSocial_footerSocialIcons__2ahZh {
    font-size: 3rem;
    margin: 0 2rem;
    color: var(--secondary-color);
}

.ThemeToggle_themeContainer__36_6z {
    margin: 0 0 0.2rem 2rem;
    width: 2.5rem;
    height: 1.7rem;
    display: grid;
    place-items: center;
}

.ThemeToggle_themeButton__1lgem {
    display: block;
    position: relative;
    width: 100%;
    height: 70%;
    border: 4px solid var(--secondary-color);
    background-color: transparent;
    outline: none;
    border-radius: 20px;
}

.ThemeToggle_themeIcon__3Tn2y {
    display: grid;
    place-items: center;
    background-color: rgba(113, 46, 255, 1);
    position: absolute;
    height: 1.8rem;
    width: 1.8rem;
    top: -9px;
    left: -7px;
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.2s ease;
}

.ThemeToggle_themeIcon__3Tn2y img {
    height: 70%;
    width: 70%;
}

.ThemeToggle_mobile__s7Ckz {
    position: absolute;
    top: 0;
    left: 0;
    margin: 2rem;
}

@media only screen and (max-width: 768px) {
    .ThemeToggle_themeContainer__36_6z {
        width: 3.4rem;
        height: 2.5rem;
    }
    .ThemeToggle_themeIcon__3Tn2y {
        width: 2.5rem;
        height: 2.5rem;
    }
}

.MobileNav_mobileNav__35xJR {
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 10vh;
  z-index: 10;
}

.MobileNav_mobileMenu__1hWZG {
  position: relative;
  margin: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  background-color: var(--secondary-color);
  outline: none;
  border: none;
  z-index: 200;
  cursor: pointer;
  z-index: 15;
}

.MobileNav_mobileNavOverlayHidden__2O67k {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.MobileNav_dark__2gHm2 {
  background-color: rgba(20, 0, 61, 0.9);
}

.MobileNav_mobileNavOptions__hzThf {
  font-size: 3rem;
  text-align: center;
}

.MobileNav_mobileMenuItem__2wltn {
  margin: 2rem;
}
.MobileNav_icon__2PnLa {
  position: absolute;
  color: #fff;
  width: 100%;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}

.MobileNav_socialContainer__27LXA {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .MobileNav_mobileNav__35xJR {
    visibility: visible;
  }
}

.NavBar_navBar__HhdFg {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10vh;
  padding: 0 15% 0 15%;
  z-index: 100;
  transition: all 0.2s ease-in-out;
}

.NavBar_navBar__HhdFg ul:nth-of-type(2) {
  display: flex;
}

.NavBar_scroll__Qd8v0 {
  height: 9vh;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.03);
}

.NavBar_scroll__Qd8v0.NavBar_dark__2LiiY {
  background-color: var(--dark-card-bg);
}

.NavBar_navBar__HhdFg a,
.NavBar_navBar__HhdFg a:link,
.NavBar_navBar__HhdFg a:visited {
  color: var(--font-color);
}

.NavBar_navBar__HhdFg a:focus,
.NavBar_navBar__HhdFg a:hover {
  color: var(--underline-color);
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

.NavBar_navBar__HhdFg.NavBar_dark__2LiiY a,
.NavBar_navBar__HhdFg.NavBar_dark__2LiiY a:link .NavBar_navBar__HhdFg.NavBar_dark__2LiiY a:visited {
  color: var(--dark-text);
}

.NavBar_navBar__HhdFg.NavBar_dark__2LiiY a:focus,
.NavBar_navBar__HhdFg.NavBar_dark__2LiiY a:hover {
  color: var(--secondary-color);
}

@media only screen and (max-width: 768px) {
  .NavBar_navBar__HhdFg {
    display: none;
  }
}

.Article_container__2NvZ0 {
  min-height: 80vh;
  width: 100%;
  padding: 5% 15% 5% 15%;
  display: grid;
  place-items: center;
}

/* .container:nth-of-type(3) {
    background-image: linear-gradient(
        transparent 250px,
        rgb(232, 234, 241) 100px,
        rgb(232, 234, 241) 50%
    );
} */

.Article_articleHeading__3AfBp {
  display: inline-block;
  font-weight: 600;
  font-size: 2.5rem;
  flex: 1 1 auto;
}

.Article_articleCard__avDof {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-evenly;
  min-height: 60%;
  width: 100%;
  padding: 5%;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  text-align: center;
  background-color: #fff;
}

.Article_articleCard__avDof p {
  margin: 1rem;
  display: inline-block;
  font-size: 1.2rem;
  flex: 1 1 auto;
  font-weight: 500;
  /* font-family: "Raleway", sans-serif; */
  font-family: "Urbanist", sans-serif;
  letter-spacing: 1px;
}

.Article_articleSpan__2L_0A {
  display: block;
  position: relative;
  margin: 1rem 0 2rem 0;
}

.Article_articleSpan__2L_0A::after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  bottom: 1;
  height: 5px;
  width: 20%;
  background: var(--underline-color);
}

.Article_dark__1LRx7 {
  color: var(--dark-text);
  background-color: var(--dark-card-bg);
}

@media only screen and (max-width: 768px) {
  .Article_container__2NvZ0 {
    padding: 5% 5% 5% 5%;
  }
  .Article_container__2NvZ0:last-of-type,
  .Article_container__2NvZ0:first-of-type {
    min-height: 50vh;
  }
}

.Technologies_container__3V-PR {
    width: 100%;
    height: auto;
    margin: auto;
}

.Technologies_technologiesContainer__e67GO {
    width: 80%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(204, 204, 204, 0.151);
}

.Technologies_imageContainer__28vw8 {
    flex: 1 1 20%;
    padding: 3rem 2rem;
}

.Technologies_image__3p9Ys {
    width: 5rem;
    height: 5rem;
    margin: auto;
}

.Technologies_buttonContainer__3CBco {
    width: 80%;
    height: auto;
    margin: 1rem auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Technologies_button__2Grf6 {
    flex-basis: 35%;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
    border-bottom: 3px solid #fff;
    background-color: #fff;
    outline: none;
}

.Technologies_dark__lOzOC {
    background-color: var(--dark-card-bg);
    border-bottom: 3px solid var(--dark-card-bg);
    color: var(--dark-text);
}

.Technologies_selected__1m__o {
    border-bottom: 3px solid var(--underline-color);
}

@media only screen and (max-width: 768px) {
    .Technologies_imageContainer__28vw8 {
        padding: 1.5rem 1rem;
    }
    .Technologies_image__3p9Ys {
        width: 4rem;
        height: 4rem;
    }
    .Technologies_button__2Grf6 {
        flex-basis: 50%;
    }
}

@media only screen and (max-width: 480px) {
    .Technologies_technologiesContainer__e67GO {
        width: 100%;
        flex-wrap: wrap;
    }
    .Technologies_buttonContainer__3CBco {
        width: 100%;
        padding: 0;
    }
    .Technologies_button__2Grf6 {
        padding: 0.5rem 0;
    }
    .Technologies_imageContainer__28vw8 {
        flex: 1 1 40%;
    }
}

.ProjectItem_project__3pwL7 {
  padding: 0.5rem;
  height: 100%;
  flex: 1 1 50%;
}

.ProjectItem_projectInner__3_d5c {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
  position: relative;
}

.ProjectItem_projectImage__1uMqR img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.ProjectItem_projectOverlay__3UP-0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  color: #fff;
  z-index: 1;
  opacity: 0;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.3s ease-in-out;
}

.ProjectItem_projectOverlay__3UP-0:focus,
.ProjectItem_projectOverlay__3UP-0:hover {
  opacity: 1;
}

.ProjectItem_projectOverlayHeader__3wXR6 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  flex: 1 1 auto;
  padding: 0.2rem;
  text-align: center;
}

.ProjectItem_projectOverlaySubHeader__BBWN9 {
  font-size: 1rem;
  color: #fff;
  flex: none;
  font-weight: 400;
  padding: 0.2rem;
  text-align: center;
}

.ProjectItem_projectOverlayLink__3otg- {
  display: inline-block;
  margin: 0.5rem;
  width: 8rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 400;
  border: 2px solid var(--secondary-color);
  border-radius: 4px;
  text-align: center;
  line-height: 2.3rem;
  color: #fff;
  transition: 0.2s ease-in-out;
  -webkit-user-select: none;
          user-select: none;
}

.ProjectItem_projectOverlayLink__3otg-:focus,
.ProjectItem_projectOverlayLink__3otg-:hover {
  background-color: var(--secondary-color);
}

.ProjectItem_projectIcon__25XOo {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  line-height: 5rem;
  text-align: right;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .ProjectItem_projectOverlayHeader__3wXR6 {
    font-size: 2rem;
  }
  .ProjectItem_projectOverlaySubHeader__BBWN9 {
    font-size: 1.5rem;
  }
  .ProjectItem_projectOverlayLink__3otg- {
    font-size: 1.5rem;
    width: 12rem;
    height: 3.5rem;
    line-height: 2.1;
  }
}

@media only screen and (max-width: 576px) {
  .ProjectItem_project__3pwL7 {
    padding: 0.5rem 0;
  }
  .ProjectItem_projectOverlay__3UP-0 {
    padding: 0.5rem;
  }
  .ProjectItem_projectOverlayLink__3otg- {
    width: 8rem;
  }
  .ProjectItem_projectOverlayHeader__3wXR6 {
    font-size: 1.5rem;
  }
  .ProjectItem_projectOverlaySubHeader__BBWN9 {
    font-size: 1rem;
  }
  .ProjectItem_projectOverlayLink__3otg- {
    width: 8rem;
    height: 2.5rem;
    font-size: 1rem;
    line-height: 2.3rem;
  }
}

.ProjectContainer_projectContainer__2qkGE {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    min-height: 80%;
    margin-top: 1rem;
    background-color: #fff;
    flex: 1 1 auto;
}

.ProjectContainer_dark__70VCA {
    background-color: var(--dark-bg);
}

@media only screen and (max-width: 768px) {
    .ProjectContainer_projectContainer__2qkGE {
        flex-direction: column;        
    }
}

.ContactForm_contactForm__3C6-9 {
    margin: auto;
    width: 60%;
    height: auto;
}

.ContactForm_form__36cyn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.ContactForm_formIcon__1HMOv {
    position: absolute;
    top: 15px;
    right: 10px;
    visibility: visible;
}

.ContactForm_formIconSuccess__wSA71 {
    color: #4bb543;
}

.ContactForm_formIcon__1HMOv.ContactForm_formIconError__2ShVt {
    color: #ff0033;
}

.ContactForm_errorMessage__3IXdG {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 5px;
    color: #ff0033;
}

.ContactForm_formControl__1h9BJ:last-of-type .ContactForm_errorMessage__3IXdG {
    bottom: 5px;
}

.ContactForm_formControl__1h9BJ {
    position: relative;
    width: 50%;
    padding: 0.25rem;
}

.ContactForm_formLabel__1CZyF {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.ContactForm_formInput__J5DmJ {
    width: 100%;
    height: 2.3rem;
    padding: 0.5rem;
    margin-bottom: 15px;
    border: none;
    border-bottom: 2px solid #b3b3b3b6;
    background-color: #f1f1f1;
}

.ContactForm_formInput__J5DmJ:focus,
.ContactForm_formInput__J5DmJ:hover {
    border-bottom: 2px solid var(--underline-color);
}

.ContactForm_form__36cyn div:last-child {
    width: 100%;
}

.ContactForm_formTextarea__3RiKz {
    width: 100%;
    height: 10rem;
    padding: 0.5rem;
    border: none;
    border-bottom: 2px solid #b3b3b3b6;
    background-color: #f1f1f1;
}

.ContactForm_dark__3Hoq2 {
    background-color: rgba(204, 204, 204, 0.151);
    color: var(--dark-text);
}

.ContactForm_submit__61Cdi {
    text-align: left;
    margin-left: 0.25rem;
}

.ContactForm_submitInput__gvWTt {
    height: 2.6rem;
    width: 10rem;
    background-color: var(--secondary-color);
    font-size: 1.1rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
        -4px -4px 12px 0 rgba(255, 255, 255, 0.3);
    transition: all ease 0.1s;
    -webkit-appearance: none;
            appearance: none;
}

.ContactForm_submitInput__gvWTt:hover {
    box-shadow: 8px 8px 12px 0 rgba(0, 0, 0, 0.25),
        -4px -4px 12px 0 rgba(255, 255, 255, 0.3);
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    transition: all ease 0.1s;
}

.ContactForm_submitInput__gvWTt.ContactForm_dark__3Hoq2 {
    box-shadow: none;
}

@media only screen and (max-width: 768px) {
    .ContactForm_contactForm__3C6-9 {
        width: 100%;
    }
    .ContactForm_form__36cyn {
        flex-direction: column;
    }
    .ContactForm_form__36cyn div {
        width: 100%;
        padding: 0.25rem;
        text-align: center;
    }
    .ContactForm_submit__61Cdi {
        text-align: center;
        margin-left: 0rem;
    }
    .ContactForm_submitInput__gvWTt {
        width: 98%;
    }
}

.Footer_footer__3kRR3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(232, 234, 241);
  padding: 1rem 1rem 0rem 1rem;
}
.Footer_iconContainer__1TlFE {
  display: flex;
}

.Footer_dark__10s62 {
  background-color: var(--dark-card-bg);
  color: var(--dark-text);
}

.Footer_copyright__2AbKt {
  text-align: center;
  padding: 0.75rem;
}

