.container {
    width: 100%;
    height: auto;
    margin: auto;
}

.technologiesContainer {
    width: 80%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(204, 204, 204, 0.151);
}

.imageContainer {
    flex: 1 1 20%;
    padding: 3rem 2rem;
}

.image {
    width: 5rem;
    height: 5rem;
    margin: auto;
}

.buttonContainer {
    width: 80%;
    height: auto;
    margin: 1rem auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    flex-basis: 35%;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
    border-bottom: 3px solid #fff;
    background-color: #fff;
    outline: none;
}

.dark {
    background-color: var(--dark-card-bg);
    border-bottom: 3px solid var(--dark-card-bg);
    color: var(--dark-text);
}

.selected {
    border-bottom: 3px solid var(--underline-color);
}

@media only screen and (max-width: 768px) {
    .imageContainer {
        padding: 1.5rem 1rem;
    }
    .image {
        width: 4rem;
        height: 4rem;
    }
    .button {
        flex-basis: 50%;
    }
}

@media only screen and (max-width: 480px) {
    .technologiesContainer {
        width: 100%;
        flex-wrap: wrap;
    }
    .buttonContainer {
        width: 100%;
        padding: 0;
    }
    .button {
        padding: 0.5rem 0;
    }
    .imageContainer {
        flex: 1 1 40%;
    }
}
