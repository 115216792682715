.themeContainer {
    margin: 0 0 0.2rem 2rem;
    width: 2.5rem;
    height: 1.7rem;
    display: grid;
    place-items: center;
}

.themeButton {
    display: block;
    position: relative;
    width: 100%;
    height: 70%;
    border: 4px solid var(--secondary-color);
    background-color: transparent;
    outline: none;
    border-radius: 20px;
}

.themeIcon {
    display: grid;
    place-items: center;
    background-color: rgba(113, 46, 255, 1);
    position: absolute;
    height: 1.8rem;
    width: 1.8rem;
    top: -9px;
    left: -7px;
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.2s ease;
}

.themeIcon img {
    height: 70%;
    width: 70%;
}

.mobile {
    position: absolute;
    top: 0;
    left: 0;
    margin: 2rem;
}

@media only screen and (max-width: 768px) {
    .themeContainer {
        width: 3.4rem;
        height: 2.5rem;
    }
    .themeIcon {
        width: 2.5rem;
        height: 2.5rem;
    }
}
