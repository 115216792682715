.mobileNav {
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 10vh;
  z-index: 10;
}

.mobileMenu {
  position: relative;
  margin: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  background-color: var(--secondary-color);
  outline: none;
  border: none;
  z-index: 200;
  cursor: pointer;
  z-index: 15;
}

.mobileNavOverlayHidden {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.dark {
  background-color: rgba(20, 0, 61, 0.9);
}

.mobileNavOptions {
  font-size: 3rem;
  text-align: center;
}

.mobileMenuItem {
  margin: 2rem;
}
.icon {
  position: absolute;
  color: #fff;
  width: 100%;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}

.socialContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .mobileNav {
    visibility: visible;
  }
}
