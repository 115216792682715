.project {
  padding: 0.5rem;
  height: 100%;
  flex: 1 1 50%;
}

.projectInner {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
  position: relative;
}

.projectImage img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.projectOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  color: #fff;
  z-index: 1;
  opacity: 0;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.3s ease-in-out;
}

.projectOverlay:focus,
.projectOverlay:hover {
  opacity: 1;
}

.projectOverlayHeader {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  flex: 1 1 auto;
  padding: 0.2rem;
  text-align: center;
}

.projectOverlaySubHeader {
  font-size: 1rem;
  color: #fff;
  flex: none;
  font-weight: 400;
  padding: 0.2rem;
  text-align: center;
}

.projectOverlayLink {
  display: inline-block;
  margin: 0.5rem;
  width: 8rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 400;
  border: 2px solid var(--secondary-color);
  border-radius: 4px;
  text-align: center;
  line-height: 2.3rem;
  color: #fff;
  transition: 0.2s ease-in-out;
  user-select: none;
}

.projectOverlayLink:focus,
.projectOverlayLink:hover {
  background-color: var(--secondary-color);
}

.projectIcon {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  line-height: 5rem;
  text-align: right;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .projectOverlayHeader {
    font-size: 2rem;
  }
  .projectOverlaySubHeader {
    font-size: 1.5rem;
  }
  .projectOverlayLink {
    font-size: 1.5rem;
    width: 12rem;
    height: 3.5rem;
    line-height: 2.1;
  }
}

@media only screen and (max-width: 576px) {
  .project {
    padding: 0.5rem 0;
  }
  .projectOverlay {
    padding: 0.5rem;
  }
  .projectOverlayLink {
    width: 8rem;
  }
  .projectOverlayHeader {
    font-size: 1.5rem;
  }
  .projectOverlaySubHeader {
    font-size: 1rem;
  }
  .projectOverlayLink {
    width: 8rem;
    height: 2.5rem;
    font-size: 1rem;
    line-height: 2.3rem;
  }
}
