* {
  --font-color: rgb(34, 34, 34);
  --secondary-color: rgba(113, 46, 255, 0.9);
  --underline-color: rgba(113, 46, 255, 0.5);
  --dark-bg: rgba(15, 0, 46, 1);
  --dark-card-bg: rgba(20, 0, 61, 1);
  --dark-text: rgba(255, 255, 255, 0.87);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-weight: 500;
  letter-spacing: 1px;
  scroll-behavior: smooth;
}

body {
  background-image: url("/src/Assets/img/backgrounds/bg.png"),
    url("/src/Assets/img/backgrounds/bg2.png"),
    url("/src/Assets/img/backgrounds/bg3.png"),
    url("/src/Assets/img/backgrounds/bg4.png"),
    url("/src/Assets/img/backgrounds/bg5.png"),
    url("/src/Assets/img/backgrounds/bg2.png");
  background-repeat: no-repeat;
  background-position: top -25px left -550px, top -400px right -550px,
    top 800px right -600px, top 1300px left -450px, top 1600px right -400px,
    top 2100px left -500px;
  background-size: 800px;
}

img {
  width: 100%;
  height: 100%;
}

.container {
  min-height: 100vh;
  width: 100%;
  padding: 5% 15% 0 15%;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
  .container {
    padding: 5% 5% 5% 5%;
  }
}
