.navBar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10vh;
  padding: 0 15% 0 15%;
  z-index: 100;
  transition: all 0.2s ease-in-out;
}

.navBar ul:nth-of-type(2) {
  display: flex;
}

.scroll {
  height: 9vh;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.03);
}

.scroll.dark {
  background-color: var(--dark-card-bg);
}

.navBar a,
.navBar a:link,
.navBar a:visited {
  color: var(--font-color);
}

.navBar a:focus,
.navBar a:hover {
  color: var(--underline-color);
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

.navBar.dark a,
.navBar.dark a:link .navBar.dark a:visited {
  color: var(--dark-text);
}

.navBar.dark a:focus,
.navBar.dark a:hover {
  color: var(--secondary-color);
}

@media only screen and (max-width: 768px) {
  .navBar {
    display: none;
  }
}
