.projectContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    min-height: 80%;
    margin-top: 1rem;
    background-color: #fff;
    flex: 1 1 auto;
}

.dark {
    background-color: var(--dark-bg);
}

@media only screen and (max-width: 768px) {
    .projectContainer {
        flex-direction: column;        
    }
}
