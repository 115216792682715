.navSocial {
    display: inline-block;
    margin-left: 2rem;
    text-align: right;
}

.socialIcons {
    font-size: 1.7rem;
    color: var(--secondary-color);
    margin: auto;
}

.mobileSocialIcons {
    color: #fff;
    font-size: 3rem;
    margin: 2rem;
}

.footerSocialIcons {
    font-size: 3rem;
    margin: 0 2rem;
    color: var(--secondary-color);
}
